import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        localesList: null
    },

    getters: {
        LOCALES(state) {
            return state.localesList;
        }
    },

    mutations: {
        SET_LOCALES_LIST(state, payload) {
            state.localesList = payload;
        }
    },

    actions: {
        GET_LOCALES_LIST: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/locales');
            commit('SET_LOCALES_LIST', data.data);
        }
    }
};


