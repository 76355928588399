import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        ourProjectsPage: null
    },

    getters: {
        OUR_PROJECTS_PAGE(state) {
            return state.ourProjectsPage;
        }
    },

    mutations: {
        SET_OUR_PROJECTS_PAGE(state, payload) {
            state.ourProjectsPage = payload;
        }
    },

    actions: {
        GET_OUR_PROJECTS_PAGE: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/our-projects');
            context.commit('SET_OUR_PROJECTS_PAGE', data.data);
        },
        UPDATE_OUR_PROJECTS_PAGE: async (context, payload) => {
            await Vue.axios.put('/api/admin/our-projects/' + payload.id, payload)
            await context.dispatch('GET_OUR_PROJECTS_PAGE');
        }
    }
};


