import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layout/AuthLayout'
import PageLayout from "./layout/PageLayout";
import auth from "./middleware/auth";

Vue.use(Router)

const router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('./pages/Login.vue'),
                }
            ]
        },
        {
            path: '/',
            redirect: 'news',
            component: PageLayout,
            children: [

                // News
                {
                    path: '/news',
                    name: 'news',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/News/list')
                },

                // News
                {
                    path: '/main',
                    name: 'main',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Main')
                },
                {
                    path: '/news/create',
                    name: 'news.create',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/News/form')
                },
                {
                    path: '/news/:id',
                    name: 'news.show',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/News/form')
                },

                // Content
                {
                    path: '/content/galleries',
                    name: 'gallery',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Gallery/list')
                },
                {
                    path: '/content/galleries/create',
                    name: 'gallery.create',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Gallery/form')
                },
                {
                    path: '/content/galleries/:id',
                    name: 'gallery.show',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Gallery/form')
                },

                // About QRCC Group
                {
                    path: '/about',
                    name: 'about',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/About/form')
                },

                // OKI
                {
                    path: '/oki',
                    name: 'oki',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Oki/form')
                },

                {
                    path: '/oki/pages',
                    name: 'oki.pages.list',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Oki/pages')
                },

                {
                    path: '/oki/pages/create',
                    name: 'oki.pages.create',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Oki/page_form')
                },
                {
                    path: '/oki/pages/:id',
                    name: 'oki.pages.show',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Oki/page_form')
                },

                // Projects
                {
                    path: '/our-projects',
                    name: 'our-projects',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Our-projects/form')
                },

                {
                    path: '/subscribers',
                    name: 'subscribers',
                    meta: {
                        middleware: auth
                    },
                    component: () => import('./pages/Subscribers/list')
                }

            ]
        },
        {path: "*", component: () => import(/* webpackChunkName: "demo" */ './pages/News/list.vue')}
    ]
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware)
        return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {from, next, to, router};
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

export default router;
