<template>
  <div class="main-content bg-default">
    <!-- Header -->
    <div class="header bg-gradient-qatar py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            xmlns="http://www.w3.org/2000/svg"
            y="0"
        >
          <polygon
              class="fill-default"
              points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import {SlideYUpTransition} from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "login";
    },
  },
};
</script>
<style>
</style>
