<template>
  <base-nav
      id="navbar-main"
      :show-toggle-button="false"
      class="navbar-top navbar-dark"
      expand
  >
    <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <div class="form-group mb-0"></div>
    </form>
    <ul class="navbar-nav align-items-end ml-auto ml-md-0">
      <base-dropdown
          class="nav-item"
          menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden"
          menu-on-right
          tag="li"
          title-tag="a"
      >
        <a slot="title" class="nav-link pr-0" href="#" @click.prevent>
          <div class="media align-items-center">
            <span
                :style="{
                'background-image': `url('/img/admin_logo.png')`,
              }"
                class="avatar avatar-sm rounded-circle avatar-image"
            >
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="copyright text-right text-lg-right" style="color: black">{{ title }}</span>
            </div>
          </div>
        </a>

        <template>
          <a class="dropdown-item" href="" @click.prevent="logout()">
            <i class="ni ni-user-run"></i>
            <span>Выйти</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
export default {
  name: 'PageNavbar',
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      title: "Profile",
    };
  },
  async created() {
    this.$store.watch(
        () => this.$store.getters["USER"],
        (me) => {
          this.title = me.name;
        }
    );
    await this.$store.dispatch("GET_USER");
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout() {
      try {
        this.$store.dispatch('logout');
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
};
</script>
