<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }" class="wrapper">
    <side-bar :background-color="sidebarBackground">
      <template slot="links">
        <sidebar-item
            :link="{
                name: 'Главная страница',
                path: '/main',
                icon: 'fas fa-home text-dark',
          }"
        />

        <sidebar-item
            :link="{
                name: 'Новости',
                path: '/news',
                icon: 'fas fa-newspaper text-dark',
          }"
        />

        <sidebar-item
            :link="{
                name: 'Галереи изображений',
                icon: 'fas fa-images text-dark',
                path: '/content/galleries'
          }"
        />

        <sidebar-item
            :link="{
                name: 'About QRCC Group',
                icon: 'fas fa-info-circle text-dark',
                path: '/about',
            }"
        />

        <sidebar-item
            :link="{
                name: 'Office of knowledge and innovation',
                icon: 'fas fa-book text-dark'
            }"
        >
          <sidebar-item
              :link="{
                name: 'Основная иформация',
                path: '/oki'
            }"
          />
          <sidebar-item
              :link="{
                name: 'Статьи OKI',
                path: '/oki/pages'
            }"
          />
        </sidebar-item>

        <sidebar-item
            :link="{
                name: 'Our projects',
                icon: 'fas fa-project-diagram text-dark',
                path: '/our-projects',
            }"
        />

        <sidebar-item
            :link="{
                name: 'Подписчики',
                icon: 'fas fa-users text-dark',
                path: '/subscribers',
            }"
        />
      </template>
    </side-bar>
    <div :data="sidebarBackground" class="main-content" @click="toggleSidebar">
      <page-navbar></page-navbar>
      <base-header class="pb-5 pb-5 pt-7 pt-md-8"></base-header>
      <fade-transition :duration="200" class="pb-5" mode="out-in" origin="center top">
        <router-view></router-view>
      </fade-transition>
    </div>
  </div>
</template>
<script>
import {FadeTransition} from "vue2-transitions";
import PageNavbar from "./PageNavbar";
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'PageLayout',
  components: {
    PageNavbar,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
