import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        subscribersList: null
    },

    getters: {
        SUBSCRIBERS_LIST(state) {
            return state.subscribersList;
        }
    },

    mutations: {
        SET_SUBSCRIBERS_LIST(state, payload) {
            state.subscribersList = payload;
        }
    },

    actions: {
        GET_SUBSCRIBERS_LIST: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/subscribers');
            commit('SET_SUBSCRIBERS_LIST', data.data);
        },
        DELETE_SUBSCRIBER: async ({commit}, payload) => {
            await Vue.axios.delete('/api/admin/subscribers/' + payload.email);
        }
    }
};


