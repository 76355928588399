import Vue from "vue";
import Vuex from "vuex";

import oki from './modules/oki';
import auth from "./modules/auth/";
import user from "./modules/user/";
import news from "./modules/news/";
import main from "./modules/main/";
import image from "./modules/image/";
import video from "./modules/video/";
import locales from "./modules/locales/";
import ourTeam from './modules/our-team';
import gallery from "./modules/gallery/";
import partners from './modules/partners';
import subscribers from "./modules/subscribers/";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
      oki,
      auth,
      user,
      main,
      news,
      image,
      video,
      locales,
      ourTeam,
      gallery,
      partners,
      subscribers
  },
  state: {
    loading: false
  },
  getters: {
    IS_LOADING(state) {
      return state.loading
    }
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    }
  }
});
