var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
              name: 'Главная страница',
              path: '/main',
              icon: 'fas fa-home text-dark',
        }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Новости',
              path: '/news',
              icon: 'fas fa-newspaper text-dark',
        }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Галереи изображений',
              icon: 'fas fa-images text-dark',
              path: '/content/galleries'
        }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'About QRCC Group',
              icon: 'fas fa-info-circle text-dark',
              path: '/about',
          }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Office of knowledge and innovation',
              icon: 'fas fa-book text-dark'
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Основная иформация',
              path: '/oki'
          }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Статьи OKI',
              path: '/oki/pages'
          }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: 'Our projects',
              icon: 'fas fa-project-diagram text-dark',
              path: '/our-projects',
          }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Подписчики',
              icon: 'fas fa-users text-dark',
              path: '/subscribers',
          }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground},on:{"click":_vm.toggleSidebar}},[_c('page-navbar'),_c('base-header',{staticClass:"pb-5 pb-5 pt-7 pt-md-8"}),_c('fade-transition',{staticClass:"pb-5",attrs:{"duration":200,"mode":"out-in","origin":"center top"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }