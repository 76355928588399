import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import VueAxios from "vue-axios";
import VueCompositionAPI from "@vue/composition-api";
import {Datetime} from 'vue-datetime'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import JsonCSV from 'vue-json-csv'


library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('download-csv', JsonCSV)

// Use section
Vue.use(Datetime)
Vue.use(VueAxios, axios);
Vue.use(ArgonDashboard)
Vue.use(VueCompositionAPI)

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const app = new Vue({
    router: router,
    store: store,
    el: "#app",
    render: h => h(App)
});

store.$app = app;