<template>
  <modal v-model="isVisible"
         gradient="danger"
         modal-classes="modal-danger modal-dialog-centered">
    <template v-slot:header>
      <h6 id="modal-title-notification" class="modal-title">{{ title }}</h6>
    </template>
    <div class="py-3 text-center">
      <template v-if="messageHeading">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">{{ messageHeading }}</h4>
      </template>
      <p>{{ message }}</p>
    </div>

    <template v-slot:footer>
      <base-button type="white" @click="_confirm">{{ okButton }}</base-button>
      <base-button class="ml-auto"
                   text-color="white"
                   type="link"
                   @click="_cancel">
        {{ cancelButton }}
      </base-button>
    </template>
  </modal>
</template>

<script>

export default {
  name: 'ConfirmDialogue',

  data: () => ({
    isVisible: false,
    // Parameters that change depending on the type of dialogue
    title: undefined,
    messageHeading: undefined, // Main text content
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Go Back', // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.messageHeading = opts.messageHeading; // Main text content
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.isVisible = true
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.isVisible = false
      this.resolvePromise(true)
    },

    _cancel() {
      this.isVisible = false
      this.resolvePromise(false)
    },
  },
}
</script>