import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        imagesList: null,
        imagesListMeta: null,
        image: {
            id: null,
            name: null,
            path: null,
            created_at: null
        }
    },

    getters: {
        IMAGES_LIST(state) {
            return state.imagesList;
        },
        IMAGES_LIST_META(state) {
            return state.imagesListMeta
        },
        IMAGE(state) {
            return state.image;
        }
    },

    mutations: {
        SET_IMAGES_LIST(state, payload) {
            state.imagesList = payload;
        },
        SET_IMAGES_LIST_META(state, payload) {
            state.imagesListMeta = payload;
        },
        SET_IMAGE(state, payload) {
            state.image = payload;
        }
    },

    actions: {
        GET_IMAGES_LIST: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/images');
            context.commit('SET_IMAGES_LIST', data.data);
            context.commit('SET_IMAGES_LIST_META', data.meta);
            return data.data;
        },
        GET_IMAGE: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/images/' + payload.id);
            context.commit('SET_IMAGE', data.data);
            return data.data;
        },
        CREATE_IMAGE: async (context, payload) => {
            let {data} = await Vue.axios.post('/api/admin/images', payload)
            context.commit('SET_IMAGE', data.data);
            return data.data;
        },
        UPDATE_IMAGE: async (context, payload) => {
            let {data} = await Vue.axios.put('/api/admin/images/' + payload.id, payload)
            context.commit('SET_IMAGE', data.data);
            return data.data;
        },
        DELETE_IMAGE: async (context, payload) => {
            let {data} = await Vue.axios.delete('/api/admin/images/' + payload.id);
            context.commit('SET_IMAGE', null);
            return data.data;
        }
    }
};


