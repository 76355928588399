import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        video: {
            id: null,
            path: null,
        }
    },

    getters: {
        VIDEO(state) {
            return state.video;
        }
    },

    mutations: {
        SET_VIDEO(state, payload) {
            state.video = payload;
        }
    },

    actions: {
        GET_VIDEO: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/videos/' + payload.id);
            context.commit('SET_VIDEO', data.data);
            return data.data;
        },
        CREATE_VIDEO: async (context, payload) => {
            let {data} = await Vue.axios.post('/api/admin/videos', payload)
            context.commit('SET_VIDEO', data.data);
            return data.data;
        },
        UPDATE_VIDEO: async (context, payload) => {
            let {data} = await Vue.axios.put('/api/admin/videos/' + payload.id, payload)
            context.commit('SET_VIDEO', data.data);
            return data.data;
        },
        DELETE_VIDEO: async (context, payload) => {
            let {data} = await Vue.axios.delete('/api/admin/videos/' + payload.id);
            context.commit('SET_VIDEO', null);
            return data.data;
        }
    }
};


