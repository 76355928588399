import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        mainPage: null,
    },

    getters: {
        MAIN_PAGE(state) {
            return state.mainPage;
        }
    },

    mutations: {
        SET_MAIN_PAGE(state, payload) {
            state.mainPage = payload;
        }
    },

    actions: {
        GET_MAIN_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.get('/api/admin/main');
            commit('SET_MAIN_PAGE', data.data);
        },
        UPDATE_MAIN_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/main/' + payload.id, payload);
            commit('SET_MAIN_PAGE', data.data);
        }
    }
};


