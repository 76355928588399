<template>
  <nav :class="[
            {'navbar-expand-md': expand},
            {'navbar-transparent': transparent},
            {[`bg-${type}`]: type}
         ]"
       class="navbar">
    <div :class="containerClasses">
      <navbar-toggle-button v-if="showToggleButton"
                            :target="contentId"
                            :toggled="toggled"
                            @click.native.stop="toggled = !toggled">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <div :id="contentId"
           v-click-outside="closeMenu"
           :class="{show: toggled}"
           class="collapse navbar-collapse">

        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)"
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar"
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
          "Explicit id for the menu. By default it's a generated random number"
    },
    containerClasses: {
      type: [String, Object, Array],
      default: 'container-fluid'
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent"
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class"
    },
    showToggleButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    closeMenu() {
      this.toggled = false;
    }
  }
};
</script>
<style>
</style>
