<template>
  <div v-show="active"
       :id="id || label"
       :aria-expanded="active"
       :class="{'active show': active}"
       class="tab-pane fade"
       @click="$emit('click')">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "tab-pane",
  props: ["label", "id", "title"],
  inject: ["addTab", "removeTab"],
  data() {
    return {
      active: false
    };
  },
  mounted() {
    this.addTab(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  }
};
</script>
<style lang="scss" scoped>

.nav-pills {

  .nav-link {
    color: black !important;

    .active {
      background-color: black !important;
    }
  }
}

</style>
