import Vue from "vue";
import router from "../../../router";
import {VueAuthenticate} from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    tokenPath: "token",
    tokenName: "token",
    loginUrl: "/api/auth/login",
});

export default {
    state: {
        isAuthenticated: !!localStorage.getItem('vue-authenticate.vueauth_token'),
    },

    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        }
    },

    mutations: {
        isAuthenticated(state, payload) {
            state.isAuthenticated = payload.isAuthenticated;
        }
    },

    actions: {
        login(context, payload) {
            return vueAuth.login(payload.user, payload.requestOptions).then(response => {
                context.commit("isAuthenticated", {
                    isAuthenticated: vueAuth.isAuthenticated()
                });
                router.push({path: '/news'});
            });
        },

        refreshToken(context, payload) {
            let {data} = Vue.axios.get('/api/auth/refresh');
            localStorage.setItem('vue-authenticate.vueauth_token', data.token)
        },

        logout(context, payload) {
            return vueAuth.logout().then(response => {
                context.commit("isAuthenticated", {
                    isAuthenticated: vueAuth.isAuthenticated()
                });
                router.push({name: "login"});
            });
        }
    }
};


