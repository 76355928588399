import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

Vue.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        localStorage.removeItem('vue-authenticate.vueauth_token');
        window.location = '/login';
    } else {
        return Promise.reject(error);
    }
});

export default {

    state: {
        user: null
    },

    getters: {
        USER(state) {
            return state.user;
        },
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = payload;
        }
    },

    actions: {
        GET_USER: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/users/me');
            context.commit('SET_USER', data.data);
        }
    }
};


