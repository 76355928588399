import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        newsList: null,
        newsListMeta: null,
        relatedNewsList: null,
        newsPage: null,
        mainArticle: {
            value: null,
            label: null
        },
        article: {
            id: null,
            locale: [{label: 'Русский', value: 'ru'}],

            title_en: null,
            body_en: null,
            desc_en: null,

            title_ru: null,
            body_ru: null,
            desc_ru: null,

            title_ar: null,
            body_ar: null,
            desc_ar: null,

            published_at: null,

            image_id: null,
            preview_id: null,
            gallery_id: null,

            related: null
        },
    },

    getters: {
        NEWS_LIST(state) {
            return state.newsList;
        },
        RELATED_NEWS_LIST(state) {
            return state.relatedNewsList;
        },
        NEWS_PAGE(state) {
            return state.newsPage
        },
        MAIN_ARTICLE(state) {
            return state.mainArticle
        },
        ARTICLE(state) {
            return state.article;
        }
    },

    mutations: {
        SET_NEWS_LIST(state, payload) {
            state.newsList = payload;
        },
        SET_RELATED_NEWS_LIST(state, payload) {
            state.relatedNewsList = payload;
        },
        SET_NEWS_PAGE(state, payload) {
            state.newsPage = payload;
        },
        SET_MAIN_ARTICLE(state, payload) {
            state.mainArticle = payload;
        },
        SET_ARTICLE(state, payload) {
            state.article = payload;
        }
    },

    actions: {
        GET_NEWS_LIST: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/news');
            commit('SET_NEWS_LIST', data.data);
        },
        GET_RELATED_NEWS_LIST: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/news/related')
            commit('SET_RELATED_NEWS_LIST', data);
        },
        GET_ARTICLE: async ({commit}, payload) => {
            let {data} = await Vue.axios.get('/api/admin/news/' + payload.id);
            commit('SET_ARTICLE', data.data);
        },
        GET_NEWS_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.get('/api/admin/newsPage');
            commit('SET_NEWS_PAGE', data.data);
        },
        GET_MAIN_ARTICLE: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/main/article');
            commit('SET_MAIN_ARTICLE', data);
        },
        SET_MAIN_ARTICLE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/main/article/' + payload.id, payload);
            commit('SET_MAIN_ARTICLE', data.data);
        },
        UPDATE_NEWS_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/newsPage/' + payload.id, payload);
            commit('SET_NEWS_PAGE', data.data);
        },
        CREATE_ARTICLE: async ({commit}, payload) => {
            let {data} = await Vue.axios.post('/api/admin/news', payload);
            commit('SET_ARTICLE', data.data);
        },
        UPDATE_ARTICLE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/news/' + payload.id, payload);
            commit('SET_ARTICLE', data.data);
        },
        DELETE_ARTICLE: async ({commit}, payload) => {
            await Vue.axios.delete('/api/admin/news/' + payload.id);
            commit('SET_ARTICLE', null);
        }
    }
};


