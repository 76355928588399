import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        galleriesList: null,
        galleriesListMeta: null,
        gallery: null
    },

    getters: {
        GALLERIES_LIST(state) {
            return state.galleriesList;
        },
        GALLERIES_LIST_META(state) {
            return state.galleriesListMeta
        },
        GALLERY(state) {
            return state.gallery;
        }
    },

    mutations: {
        SET_GALLERIES_LIST(state, payload) {
            state.galleriesList = payload;
        },
        SET_GALLERIES_LIST_META(state, payload) {
            state.galleriesListMeta = payload;
        },
        SET_GALLERY(state, payload) {
            state.gallery = payload;
        }
    },

    actions: {
        GET_GALLERIES_LIST: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/galleries');
            context.commit('SET_GALLERIES_LIST', data.data);
        },
        GET_GALLERY: async (context, payload) => {
            let {data} = await Vue.axios.get('/api/admin/galleries/' + payload.id);
            context.commit('SET_GALLERY', data.data);
        },
        CREATE_GALLERY: async (context, payload) => {
            let {data} = await Vue.axios.post('/api/admin/galleries', payload)
            context.commit('SET_GALLERY', data.data);
        },
        UPDATE_GALLERY: async (context, payload) => {
            let {data} = await Vue.axios.put('/api/admin/galleries/' + payload.id, payload)
            context.commit('SET_GALLERY', data.data);
        },
        DELETE_GALLERY: async (context, payload) => {
            let {data} = await Vue.axios.delete('/api/admin/galleries/' + payload.id);
            context.commit('SET_GALLERY', null);
        }
    }
};


