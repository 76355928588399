import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        page: null,
        okiPage: null,
        pagesList: null
    },

    getters: {
        PAGE(state) {
            return state.page;
        },
        OKI_PAGE(state) {
            return state.okiPage;
        },
        PAGES_LIST(state) {
            return state.pagesList;
        }
    },

    mutations: {
        SET_PAGE(state, payload) {
            state.page = payload;
        },
        SET_OKI_PAGE(state, payload) {
            state.okiPage = payload;
        },
        SET_PAGES_LIST(state, payload) {
            state.pagesList = payload;
        }
    },

    actions: {
        GET_PAGES_LIST: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/pages');
            commit('SET_PAGES_LIST', data.data);
        },
        GET_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.get('/api/admin/pages/' + payload.id);
            commit('SET_PAGE', data.data);
        },
        CREATE_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.post('/api/admin/pages', payload);
            commit('SET_PAGE', data.data);
        },
        UPDATE_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/pages/' + payload.id, payload);
            commit('SET_PAGE', data.data);
        },
        DELETE_PAGE: async ({commit}, payload) => {
            await Vue.axios.delete('/api/admin/pages/' + payload.id);
            commit('SET_PAGE', null);
        },

        GET_OKI_PAGE: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/oki');
            commit('SET_OKI_PAGE', data.data);
        },
        UPDATE_OKI_PAGE: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/oki/' + payload.id, payload);
            commit('SET_OKI_PAGE', data.data);
        }
    }
};


