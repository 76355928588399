import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    state: {
        ourTeam: null
    },

    getters: {
        OUR_TEAM(state) {
            return state.ourTeam;
        }
    },

    mutations: {
        SET_OUR_TEAM(state, payload) {
            state.ourTeam = payload;
        }
    },

    actions: {
        GET_OUR_TEAM: async ({commit}) => {
            let {data} = await Vue.axios.get('/api/admin/our-team');
            commit('SET_OUR_TEAM', data.data);
        },
        UPDATE_OUR_TEAM: async ({commit}, payload) => {
            let {data} = await Vue.axios.put('/api/admin/our-team/' + payload.id, payload);
            commit('SET_OUR_TEAM', data.data);
        }
    }
};


